import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

// 静态路由
export const constantRoutes = [
  {
    path: "/",
    component: () => import("../views/index/Index.vue"),
  },
  {
    path: "/solution/index",
    component: () => import("../views/solution/Index.vue"),
  },
  // c端
  {
    path: "/c/metaverse",
    component: ()=> import("../views/c/metaverse/Index.vue")
  },
  {
    path: "/c/member",
    component: ()=> import("../views/c/member/Index.vue")
  },
  {
    path: "/solution/subBrand", //子品牌
    component: () => import("../views/solution/subBrand.vue"),
  },
  {
    path: "/solution/twinFarm", //孪生农场
    component: () => import("../views/solution/twinFarm.vue"),
  },
  {
    path: "/solution/openShop", //开店服务
    component: () => import("../views/solution/openShop.vue"),
  },
  {
    path: "/solution/storeOperations", //店铺运营
    component: () => import("../views/solution/storeOperations.vue"),
  },
  {
    path: "/solution/product", //农产品朔源
    component: () => import("../views/solution/product.vue"),
  },
  {
    path: "/recruit/recruiting",
    component: () => import("../views/recruit/recruitment.vue"),
  },
  {
    path: "/recruit/recruitDetail",
    component: () => import("../views/recruit/recruitDetail.vue"),
  },
  {
    path: "/recruit/recruitDesc",
    component: () => import("../views/recruit/recruitDesc.vue"),
  },
  {
    path: "/assets/assetsManagement",
    component: () => import("../views/assets/assetsManagement.vue"),
  },
  {
    path: "/kitchen/kitchen",
    component: () => import("../views/kitchen/kitchen.vue"),
  },
  {
    path: "/kitchen/purchase",
    component: () => import("../views/kitchen/purchase.vue"),
  },
  {
    path: "/kitchen/menu",
    component: () => import("../views/kitchen/menuPage.vue"),
  }
];

const createRouter = () =>
  new Router({
    mode: "hash",
    // scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes,
  });

const router = createRouter();

export default router;
