const user = {

  namespaced: true,

  state: {
  },
  
  mutations: {
    
  },
  
  actions: {
  }
}

export default user