import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "@/assets/css/base.css";
import "@/assets/js/flexable.js"
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.config.productionTip = false

Vue.use(ElementUI)
router.afterEach((to,from,next) => {
  window.scrollTo(0,0);
})
new Vue({
  router,
  store,
  beforeCreate(){
		Vue.prototype.$bus = this	//安装全局事件总线
	},
  render: h => h(App),
}).$mount('#app')
